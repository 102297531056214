/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react'

import HtmlHead from '../components/html-head'
import ScrollReveal from './scroll-reveal'

import '../styles/main.scss'

type LayoutProps = {
  children: React.ReactNode
  id: string
  subtitle?: string
  pageContext: unknown
}

const Layout: React.FC<LayoutProps> = ({
  children,
  id,
  subtitle,
  pageContext,
}) => (
  <div id={id}>
    <HtmlHead subtitle={subtitle} pageContext={pageContext} />
    <ScrollReveal>{children}</ScrollReveal>
  </div>
)

export default Layout
