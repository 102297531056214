import $ from 'jquery'

const scrollToAnchor = (hash: string) => {
  const target = $(hash)
  const offset = target.offset()
  if (offset !== undefined) {
    const position = offset.top
    $('body,html').stop().animate({ scrollTop: position }, 500)
  }
}

export const addScrollToAnchor = (): void => {
  $('a[href^="#"]').on('click', event => {
    const href = $(event.target).attr('href') as string
    const hash = href === '#' || href === '' ? 'html' : href
    scrollToAnchor(hash)

    return false
  })
}

export const scrollTop = (): number => {
  if (typeof window !== `undefined`) {
    return Math.max(
      // なんかブラウザによってとり方が違うようなので全部もってきてMaxをとる
      window.pageYOffset,
      document.documentElement.scrollTop,
      document.body.scrollTop,
    )
  }

  return 0
}

export const getRedirectLanguage = (): string => {
  if (typeof navigator === `undefined`) {
    return '/'
  }

  const lang =
    navigator && navigator.language && navigator.language.split('-')[0]
  if (!lang) return '/'

  switch (lang) {
    case 'ja':
      return '/ja/'
    default:
      return '/'
  }
}
