import * as React from 'react'
import { PageProps } from 'gatsby'
import { Link, changeLocale } from 'gatsby-plugin-intl'

import { scrollTop } from '../utils'

type HeaderProps = Pick<PageProps, 'location'> & Pick<PageProps, 'pageContext'>

const Header: React.FC<HeaderProps> = ({ location, pageContext }) => {
  const [scrollTopState, setScrollTopState] = React.useState(0)
  const [menuActiveState, setMenuActiveState] = React.useState(false)

  if (typeof window !== `undefined`) {
    window.onscroll = () => {
      setScrollTopState(scrollTop())
    }
  }

  const menuClickHandler = () => {
    setMenuActiveState(!menuActiveState)
  }

  const selectLanguageHandler = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    changeLocale(event.target.value)
  }

  const headerClassName = [
    scrollTopState > 100 ? 'fixed' : '',
    menuActiveState ? 'active' : '',
  ].join(' ')

  const menuClassName = ['menu', menuActiveState ? 'active' : ''].join(' ')
  const { language } = pageContext as { language: string }

  return (
    <>
      <header className={headerClassName}>
        <div className="inner">
          <nav>
            <h1>
              <Link to="/">
                <img
                  src="/images/embodyme-logo.png"
                  srcSet="/images/embodyme-logo.png 1x, /images/embodyme-logo@2x.png 2x"
                  width={209}
                  height={59}
                  alt="embodyme-logo"
                  loading="eager"
                />
              </Link>
            </h1>
            <ul>
              <li>
                {location.pathname === '/' ? (
                  <a href="#products">Products</a>
                ) : (
                  <Link to="/#products">Products</Link>
                )}
              </li>
              <li>
                <Link to="/company">Company</Link>
              </li>
              <li>
                <Link to="/press">Press</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </nav>

          <div className="header-end">
            <ul className="social">
              <li>
                <a
                  href="https://www.instagram.com/xpression_app/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/images/instagram.svg"
                    alt="instagram"
                    width={24}
                    height={24}
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/xpression.jp"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/images/facebook.svg"
                    alt="facebook"
                    width={24}
                    height={24}
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/xpression_app"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/images/twitter.svg"
                    alt="twitter"
                    width={24}
                    height={24}
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/embodyme-inc"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/images/linkdin.svg"
                    alt="linkdin"
                    width={24}
                    height={24}
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://www.tiktok.com/@xpression_app/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/images/tiktok.svg"
                    alt="tiktok"
                    width={24}
                    height={24}
                  />
                </a>
              </li>
            </ul>
            <div className="select-language">
              <select onChange={selectLanguageHandler} defaultValue={language}>
                <option value="en">
                  English
                </option>
                <option value="ja">
                  日本語
                </option>
              </select>
            </div>
          </div>
          <p className="header-copyright">© EmbodyMe, Inc.</p>
        </div>
      </header>
      <div
        className={menuClassName}
        onClick={menuClickHandler}
        role="presentation"
      />
    </>
  )
}

export default Header
