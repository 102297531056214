import * as React from 'react'
import scrollReveal from 'scrollreveal'

interface ScrollRevealProps {
  style?: React.CSSProperties
}

const ScrollReveal: React.FC<ScrollRevealProps> = ({ children, style }) => {
  const divRef = React.useRef<HTMLDivElement>(null)
  React.useEffect(() => {
    if (divRef.current) {
      scrollReveal().reveal('.reveal', {
        reset: false,
        easing: 'ease',
        duration: 1400,
        opacity: 0,
        scale: 1,
      })
      scrollReveal().reveal('.fadeup', {
        reset: false,
        origin: 'bottom',
        easing: 'ease',
        duration: 1000,
        distance: '20px',
        delay: 300,
        opacity: 0,
        scale: 1,
      })
      scrollReveal().reveal('.fadeup-delay', {
        reset: false,
        origin: 'bottom',
        easing: 'ease',
        duration: 1000,
        distance: '20px',
        delay: 500,
        opacity: 0,
        scale: 1,
      })
      scrollReveal().reveal('.fadein', {
        reset: false,
        origin: 'right',
        easing: 'ease',
        duration: 1000,
        distance: '20px',
        delay: 300,
        opacity: 0,
        scale: 1,
      })
    }
  }, [])

  return (
    <div ref={divRef} style={style}>
      {children}
    </div>
  )
}

export default ScrollReveal
