import * as React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

import { SiteDataQuery } from '../../types/graphql-types'

type HtmlHeadProps = {
  subtitle?: string
  pageContext: unknown
}

const HtmlHead: React.FC<HtmlHeadProps> = ({ subtitle, pageContext }) => {
  const data: SiteDataQuery = useStaticQuery(
    graphql`
      query siteData {
        site {
          siteMetadata {
            description
            title
            name
          }
        }
      }
    `,
  )

  const title = data.site?.siteMetadata?.title ?? ''
  const description = data.site?.siteMetadata?.description ?? ''
  const siteName = data.site?.siteMetadata?.name ?? ''
  const pageTitle = subtitle ? `${subtitle} | ${title}` : title
  const { language } = pageContext as { language: string }

  return (
    <Helmet htmlAttributes={{ lang: language }}>
      <meta httpEquiv="Content-Language" content={language} />
      <title>{pageTitle}</title>
      <meta name="description" content={description} />
      <meta name="viewport" content="width=device-width,initial-scale=1" />
      <meta property="og:title" content={siteName} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://embodyme.com/" />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content={siteName} />
      <meta property="og:image" content="https://embodyme.com/images/ogp.png" />
      <meta property="og:locale" content="en_US" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="format-detection" content="telephone=no" />
      <link rel="preconnect" href="https://fonts.gstatic.com" />
    </Helmet>
  )
}

export default HtmlHead
