import * as React from 'react'
import { PageProps } from 'gatsby'
import { Link } from 'gatsby-plugin-intl'

type FooterProps = Pick<PageProps, 'location'>

const Footer: React.FC<FooterProps> = ({ location }) => (
  <footer>
    <div className="inner">
      <h1>
        <Link to="/">
          <img
            src="/images/embodyme-logo.png"
            srcSet="/images/embodyme-logo.png 1x, /images/embodyme-logo@2x.png 2x"
            width={209}
            height={59}
            alt="embodyme-logo"
            loading="eager"
          />
        </Link>
      </h1>
      <nav>
        <ul>
          <li>
            {location.pathname === '/' ? (
              <a href="#products">Products</a>
            ) : (
              <Link to="/#products">Products</Link>
            )}
          </li>
          <li>
            <Link to="/company">Company</Link>
          </li>
          <li>
            <Link to="/press">Press</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </nav>
      <ul className="social">
        <li>
          <a
            href="https://www.instagram.com/xpression_app/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/images/instagram.svg"
              alt="instagram"
              width={24}
              height={24}
            />
          </a>
        </li>
        <li>
          <a
            href="https://www.facebook.com/xpression.jp"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/images/facebook.svg"
              alt="facebook"
              width={24}
              height={24}
            />
          </a>
        </li>
        <li>
          <a
            href="https://twitter.com/xpression_app"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/images/twitter.svg"
              alt="twitter"
              width={24}
              height={24}
            />
          </a>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/company/embodyme-inc"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/images/linkdin.svg"
              alt="linkdin"
              width={24}
              height={24}
            />
          </a>
        </li>
        <li>
          <a
            href="https://www.tiktok.com/@xpression_app/"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/images/tiktok.svg" alt="tiktok" width={24} height={24} />
          </a>
        </li>
      </ul>
      <p className="footer-copyright">© EmbodyMe, Inc.</p>
    </div>
  </footer>
)

export default Footer
